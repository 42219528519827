import { Col, Row, Card, Button, Modal, Popconfirm } from 'antd'
import { SettingFilled } from '@ant-design/icons'
import { useState, useEffect, useRef } from 'react'
import { places } from "./RandomPlacesData"
import { RandomPlacesService, deleteActivities } from './RandomPlacesServices'
import { IPlaces } from './RandomPlacesServices'
import { Fireworks } from '@fireworks-js/react'
import type { FireworksHandlers } from '@fireworks-js/react'
import { AnimatePresence, motion } from 'framer-motion'
import { RandomPlacesEdit } from './RandomPlacesEdit'

export const RandomPlaces = () => {
    //State
    const [value1, setValue1] = useState<String>('1')
    const [value2, setValue2] = useState<String>('2')
    const [value3, setValue3] = useState<String>('3')
    const [result, setResult] = useState<IPlaces | null>(null)
    const [isRolling, setIsRolling] = useState<boolean>(false)
    const [showFirework, setShowFirework] = useState<boolean>(false)
    const [activitiesList, setActivitiesList] = useState(places)

    //Modal
    const [open, setOpen] = useState(false);

    //Ref for fireworks
    const ref = useRef<FireworksHandlers>(null)

    // Random values for 3 dices
    const Roll3Dices = () => {
        setValue1(RandomPlacesService(activitiesList)?.activity)
        setValue2(RandomPlacesService(activitiesList)?.activity)
        setValue3(RandomPlacesService(activitiesList)?.activity)
    }

    //First set value for rolling screen
    useEffect(() => {
        toggleFirework()
        Roll3Dices()
    }, [])

    //Rolling
    const Rolling = () => {
        setIsRolling(true)
        setResult(null)

        //rolling on screen
        const rollDicesInterval = setInterval(() => {
            Roll3Dices()
        }, 50)

        //show result
        setTimeout(() => {
            clearInterval(rollDicesInterval)
            setIsRolling(false)

            //set Result
            const result = RandomPlacesService(activitiesList)
            setResult(result)
            setValue1(result.activity)
            setValue2(result.activity)
            setValue3(result.activity)

            // Firework controll
            toggleFirework()
            setShowFirework(true)

            const fireWorkTimeout = setTimeout(() => {
                toggleFirework()
                setShowFirework(false)
                clearTimeout(fireWorkTimeout)
            }, 5500)

        }, 5000)
    }

    //Toggle fireworks
    const toggleFirework = () => {
        if (!ref.current) return
        if (ref.current.isRunning) {
            ref.current.stop()
        } else {
            ref.current.start()
        }
    }

    //handle Remove
    const handleRemove = () => {
        const status = deleteActivities(result, activitiesList, setActivitiesList)
        if (status) {
            setResult(null)
        }
    }

    return (
        <>
            {/* Modal */}
            <Modal title="SETTINGS" open={open} onCancel={() => setOpen(false)} footer={null}>
                <RandomPlacesEdit activitiesList={activitiesList} setActivitiesList={setActivitiesList} setResult={setResult} />
            </Modal>

            {/* Random boxes */}
            <Row className="randomPlacesCardContainer">
                <Col>
                    <Card className='card' bordered={false}>
                        {value1}
                    </Card>
                </Col>
                <Col>
                    <Card className='card' bordered={false}>
                        {value2}
                    </Card>
                </Col>
                <Col>
                    <Card className='card' bordered={false}>
                        {value3}
                    </Card>
                </Col>
            </Row>

            {/* Roll Button */}
            <div className='randomFoodRollBtn'>
                <Button
                    size="large"
                    type="primary"
                    loading={isRolling}
                    onClick={Rolling}
                >
                    START
                </Button>
                <Button
                    className='randomPlacesSettings'
                    type="text"
                    disabled={isRolling}
                    icon={<SettingFilled />}
                    onClick={() => setOpen(true)}
                ></Button>
            </div>

            {/* Display result */}
            <AnimatePresence>
                {result && <motion.div
                    className="ResultContainer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <div className="resultTitle">Congratulations</div>
                    <div className="resultContent">
                        Your activity is {result.activity} <Popconfirm
                            title={`Do you want to delete ${result.activity}`}
                            onConfirm={handleRemove}
                        >
                            <span
                                className='removeBtn'

                            >
                                (Remove)
                            </span>
                        </Popconfirm>
                    </div>
                </motion.div>}
            </AnimatePresence>

            {/* Firework */}
            <AnimatePresence>
                {showFirework && <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.7 }}
                >
                    <Fireworks
                        ref={ref}
                        options={{ opacity: 1 }}
                        style={{
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            position: 'fixed',
                            background: '#000'
                        }}
                    />
                </motion.div>}
            </AnimatePresence>
        </>
    )
}