export const foods = [
    {
        id: 1,
        name: 'Gà rán',
    },
    {
        id: 2,
        name: 'Cơm tấm',
    },
    {
        id: 3,
        name: 'Mì cay',
    },
    {
        id: 4,
        name: 'Hủ tiếu',
    },
    {
        id: 5,
        name: 'Bún đậu',
    },
    {
        id: 6,
        name: 'Bún chả',
    },
    {
        id: 7,
        name: 'Mì trộn',
    },
    {
        id: 8,
        name: 'Bún Thái',
    },
    {
        id: 9,
        name: 'Lẩu chay',
    },
    {
        id: 10,
        name: 'Bánh xèo',
    },
]