import "../Random.scss";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect, useRef } from 'react'
import { foods } from './FoodData'
import { GetRandomFoodsID } from "./RandomFoodsServices";
import { message, Modal, Popconfirm } from "antd";
import { SettingFilled, DeleteOutlined } from '@ant-design/icons'
import { FoodsRandomEdit } from "./FoodsRandomEdit";
import { IFoods, DeleteFood } from "./RandomFoodsServices"
import { Fireworks } from '@fireworks-js/react'
import type { FireworksHandlers } from '@fireworks-js/react'

export const RandomFoods = () => {
    const [height, setHeight] = useState(window.innerHeight)
    const [width, setWidth] = useState(window.innerWidth)
    const [showFirework, setShowFirework] = useState<boolean>(false)

    //listen screen resize
    useEffect(() => {
        const setSize = () => {
            setHeight(window.innerHeight)
            setWidth(window.innerWidth)
        }

        window.addEventListener("resize", () => setSize());
        return window.removeEventListener("resize", () => setSize())
    }, [])

    //Ref for fireworks
    const ref = useRef<FireworksHandlers>(null)

    // Foods list
    const [foodsList, setFoodsList] = useState(foods)

    //Modal
    const [open, setOpen] = useState(false);

    //States
    const [showFood, setShowFood] = useState<boolean>(true)
    const [isRandom, setIsRandom] = useState<boolean>(false)
    const [foodResult, setFoodResult] = useState<IFoods | null>(null)
    const [firstRoll, setFirstRoll] = useState<boolean>(true)
    const [isRolling, setIsRolling] = useState<boolean>(false)

    //animation variables
    const middleX = 0.45 * width;
    const middleY = 0.4 * height;
    const topY = height * 0.3;
    const topX = width * 0.33;
    const originAnimate = {
        opacity: 0.5,
        x: middleX,
        y: middleY,
        zIndex: -1,
    }
    const exitAnimate = {
        opacity: 0,
        x: 0.45 * window.innerWidth,
        y: 0.4 * window.innerHeight,
    }

    //Clicking actions image
    const actionsImageOnClick = () => {
        // If is not rolling -> roll, else do nothing
        if (!isRandom) {
            if (showFood) {
                //False the first roll
                setFirstRoll(false)
                //Check if it is random
                setIsRandom(true)
                //Remove all food on the screen
                setShowFood(false)
                //Rolling
                setIsRolling(true)

                //Rolling result
                const rollingTimeout = setTimeout(() => {

                    // Result running interval
                    const RollingInterval = setInterval(() => {
                        setFoodResult(GetRandomFoodsID(foodsList))
                    }, 50)

                    //Set the result after ... seconds
                    const foodTimeout = setTimeout(() => {

                        //random
                        let result = GetRandomFoodsID(foodsList);
                        while (!result) {
                            result = GetRandomFoodsID(foodsList);
                        }

                        // Message
                        firstRoll && message.info('You can remove result from the list or click the image to roll again', 5.5)

                        //end random
                        setIsRandom(false)
                        setIsRolling(false)
                        //set food
                        setFoodResult(result)
                        //clear timeout
                        clearTimeout(foodTimeout)
                        //clear interval
                        clearInterval(RollingInterval)

                        // Firework controll
                        toggleFirework()
                        setShowFirework(true)

                        // Firework
                        const fireWorkTimeout = setTimeout(() => {
                            toggleFirework()
                            setShowFirework(false)
                            clearTimeout(fireWorkTimeout)
                        }, 5500)
                    }, (3 * (foodsList.length + 1) * 0.2) * 1000) //3 seconds + each food 0.25s
                }, foodsList.length * 0.18 * 1000) //wait for items to all exit, each items = 0.18s
            } else {
                //clear result
                setFoodResult(null)
                setShowFood(true)
            }
        }

    }

    //handle remove result
    const handleRemoveResult = () => {
        if (foodsList.length <= 2) {
            message.error('Can not remove, list must be at least 2 foods')
        } else {
            foodResult && DeleteFood(foodResult, foodsList, setFoodsList)
            actionsImageOnClick()
        }
    }

    //Toggle fireworks
    const toggleFirework = () => {
        if (!ref.current) return
        if (ref.current.isRunning) {
            ref.current.stop()
        } else {
            ref.current.start()
        }
    }
    return (
        <>
            {/* Modal */}
            <Modal title="SETTINGS" open={open} onCancel={() => setOpen(false)} footer={null}>
                <FoodsRandomEdit foodsList={foodsList} setFoodsList={setFoodsList} />
            </Modal>
            <SettingFilled className="editButton" onClick={() => setOpen(true)} />

            {/* Result */}
            <AnimatePresence>
                {foodResult && <motion.div
                    className="foodResult"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <>
                        {foodResult?.name}
                        <Popconfirm title={`Do you want to remove ${foodResult.name}?`}
                            onConfirm={handleRemoveResult}
                        >
                            {!isRolling && <DeleteOutlined className="deleteBtn deleteResult" />}
                        </Popconfirm>
                    </>
                </motion.div>}
            </AnimatePresence>

            {/* Action images */}
            <motion.span className="randomActionBtn"
                onClick={actionsImageOnClick}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <img className={`Action Action1 ${isRandom && 'ActionGlow'}`} src="./Images/action1.png" />
                <img className={`Action Action2 ${isRandom && 'ActionGlow'}`} src="./Images/action2.png" />

            </motion.span>


            {/* Foods list */}
            <AnimatePresence>
                {showFood && foodsList.map((food, i) => {
                    return (
                        <motion.span
                            className="food"
                            key={(i + 1)}
                            initial={originAnimate}
                            animate={{
                                opacity: 1,
                                zIndex: 4,
                                x: (width < 600 ? -(width * 0.045) : 0) + (((i + 1) % 2 === 0) ? middleX - topX : middleX + topX),
                                y: (middleY - topY + (((i + 1)%2 > 0 ? (i + 1) : i) * (middleY - topY)) / 1.6),
                            }}
                            exit={exitAnimate}
                            transition={{
                                duration: 0.5,
                                delay: i * 0.2,
                            }}
                        >
                            {food.name}
                        </motion.span>
                    )
                })}
            </AnimatePresence>

            {/* Firework */}
            <AnimatePresence>
                {showFirework && <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.7 }}
                >
                    <Fireworks
                        ref={ref}
                        options={{ opacity: 1 }}
                        style={{
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            position: 'fixed',
                            background: '#000',
                        }}
                    />
                </motion.div>}
            </AnimatePresence>
        </>
    )
}