import { message } from "antd";
import { places } from "./RandomPlacesData";

export interface IPlaces {
    id: number;
    activity: string;
}

export const RandomPlacesService = (places: IPlaces[]) => {
    //random
    let randomNumber = Math.round(Math.random() * places.length) - 1;
    // console.log(places[randomNumber])
    while (!places[randomNumber]) {
        randomNumber = Math.round(Math.random() * places.length) - 1;
    }

    return places[randomNumber]
}

export const addActivities = (inputName: string, activitiesList: IPlaces[], setActivitiesList: any, setInputName: any) => {
    if (inputName.trim().length === 0) {
        message.error('Activity can not be empty')
    } else {
        message.success(`Added activity ${inputName} successfully`)

        //generate new food
        const randomID = Math.random() * Date.now();
        const newFood = {
            id: randomID,
            activity: inputName,
        }

        setActivitiesList([...activitiesList, newFood]);
        setInputName('');
    }
}

//remove activity
export const deleteActivities = (place: IPlaces | null, placesList: IPlaces[], setPlacesList: any) => {
    if (placesList.length <= 2) {
        message.error('Can not remove, list must be at least 2 activities to random')
        return 0;
    } else {
        const result = placesList.filter(filterPlace => filterPlace.id !== place?.id && filterPlace)
        message.success(`Removed ${place?.activity} from the list successfully`)
        setPlacesList(result)
        return 1
    }
}

export const resetActivities = (setActivitiesList: any, setResult:any) => {
    setActivitiesList(places)
    setResult(null)
    message.success(`Reset activities list successfully`)
}