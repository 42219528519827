import dayjs from 'dayjs'
import { FirstDate } from './DaysCounter'

export function GetCurrTime() {
    const dateString = `${FirstDate.get('year')}-${FirstDate.get('month')}-${FirstDate.get('date')}`

    const today = dayjs();
    const times = today.diff(dateString, 'second')
    const days = Math.floor(times / 60 / 60 / 24)
    const hours = Math.floor((times - days * 60 * 60 * 24) / 60 / 60)
    const minutes = Math.floor((times - days * 60 * 60 * 24 - hours * 60 * 60) / 60)
    const seconds = (times - days * 60 * 60 * 24 - hours * 60 * 60 - minutes * 60)

    // Calculate years
    const diffYears = today.diff(FirstDate, 'year');

    // Calculate months and remaining days
    const remainingMonths = today.subtract(diffYears, 'year').diff(FirstDate, 'month');
    const remainingDays = today.subtract(diffYears, 'year').subtract(remainingMonths, 'month').diff(FirstDate, 'day');

    return {
        year: diffYears,
        month: remainingMonths + 1,
        date: remainingDays,
        day: days,
        hour: hours,
        minute: minutes,
        second: seconds,
    }
}

export const GetUniqueId = () => {
    return Math.floor(Math.random() * Date.now()).toString()
}