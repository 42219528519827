import { message } from 'antd'
import { foods } from './FoodData';

export interface IFoods {
    id: number;
    name: string;
}
export const GetRandomFoodsID = (foodsList: IFoods[]): IFoods => {
    //random
    let randomNumber = Math.round(Math.random() * foodsList.length) - 1;
    // console.log(foodsList[randomNumber])
    return foodsList[randomNumber]
}

//delete food
export const DeleteFood = (food: IFoods, foodsList: IFoods[], setFoodsList: any) => {
    if (foodsList.length <= 2) {
        message.error('Can not delete, list must be at least 2 foods')
    } else {
        const result = foodsList.filter((foodFiltered: IFoods) => foodFiltered.id !== food.id && food)
        setFoodsList(result)
        message.success(`Deleted ${food.name} from the list successfully`)
    }
}

//add food
export const AddFood = (inputName: string, foodsList: IFoods[], setFoodsList: any, setInputName: any) => {
    if (inputName.trim().length === 0) {
        message.error('Food name can not be empty')
    } else {
        message.success(`Added ${inputName} successfully`)

        //generate new food
        const randomID = Math.random() * Date.now();
        const newFood = {
            id: randomID,
            name: inputName,
        }

        setFoodsList([...foodsList, newFood]);
        setInputName('');
    }
}

//handle reset
export const ResetFoodList = (setFoodsList: any) => {
    message.success("Reset successfully")
    setFoodsList(foods)
}