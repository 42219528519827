import "./DaysCounter.scss";
import { useState, useEffect, useCallback } from "react";
import { HeartFilled } from '@ant-design/icons'
import { motion, AnimatePresence } from 'framer-motion'
import dayjs from 'dayjs'
import { IPersonInfo, PersonModel } from './PersonModel'
import { GetCurrTime, GetUniqueId } from './DaysCounterServices'

export interface IHearts {
    id: string;
}

export const FirstDate = dayjs().date(20).month(5).year(2023)
const firstPersonImage = 'firstPerson.png'
const secondPersonImage = 'secondPerson.png'

//person info
const firstPersonInfo: IPersonInfo = {
    name: 'Gia Khang',
    birth: dayjs().date(30).month(5).year(2003),
}

const secondPersonInfo: IPersonInfo = {
    name: 'Mỹ Linh',
    birth: dayjs().date(3).month(3).year(2004),
}

export const DaysCounter = () => {
    //use Effect
    useEffect(() => {
        //first in
        setTime(GetCurrTime())

        //interval
        const setTimeInterval = setInterval(() => {
            setTime(GetCurrTime())
            setToday(dayjs())
        }, 1000)

        return () => clearInterval(setTimeInterval)
    }, [])

    //states
    const [today, setToday] = useState(dayjs())
    const [time, setTime] = useState({
        year: 0,
        month: 0,
        date: 0,
        day: 0,
        hour: 0,
        minute: 0,
        second: 0
    })

    //heart controller
    const [hearts, setHearts] = useState<IHearts[]>([])

    //remove hearts that run outside of the screen
    const removeHeart = useCallback((id: string) => {
        setHearts(oldHearts => [...oldHearts.filter(heart => heart.id !== id)])
    }, [])

    //view controller
    const [isViewingDays, setIsViewingDays] = useState<boolean>(true)

    return (
        <>
            <AnimatePresence>
                {hearts.length > 0 && <motion.div className="pinkyEffect"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1.5 } }}
                    exit={{ opacity: 0, transition: { duration: 1.8 } }}>

                </motion.div>}
            </AnimatePresence>
            

            <div className="CounterContainer">
                {/* First person */}
                <PersonModel image={firstPersonImage} person={firstPersonInfo} />


                {/* Count */}
                <div className="count">
                    <div className="totalTime">

                        {isViewingDays ?
                            <AnimatePresence>
                                <motion.div className="Days"
                                    onClick={() => setIsViewingDays(!isViewingDays)}
                                    initial={{ opacity: 0, y: '30px' }}
                                    animate={{ opacity: 1, y: '0', transition: { duration: 0.5 } }}
                                    exit={{ opacity: 0, y: '-30px', transition: { duration: 0.5 } }}
                                >
                                    {time.day} Days
                                </motion.div>
                            </AnimatePresence>
                            : <motion.div className="FullTime"
                                onClick={() => setIsViewingDays(!isViewingDays)}
                                initial={{ opacity: 0, y: '30px' }}
                                animate={{ opacity: 1, y: '12px', transition: { duration: 0.3} }}
                                exit={{ opacity: 0, y: '-30px', transition: { duration: 0.3 } }}
                            >
                                {time.year} Y - {time.month} M - {time.date} D
                            </motion.div>}
                        <motion.div
                            className="Times"
                            initial={{ opacity: 0, y: '30px' }}
                            animate={{ opacity: 1, y: '0', transition: { duration: 0.6, delay: 0.14 } }}
                            exit={{ opacity: 0, y: '-30px', transition: { duration: 0.6 } }}>
                            {time.hour < 10 ? `0${time.hour}` : time.hour}:{time.minute < 10 ? `0${time.minute}` : time.minute}:{time.second < 10 ? `0${time.second}` : time.second}
                        </motion.div>

                    </div>
                    <motion.div
                        initial={{ opacity: 0, y: '30px' }}
                        animate={{ opacity: 1, y: '0', transition: { duration: 0.6, delay: 0.28 } }}
                        exit={{ opacity: 0, y: '-30px', transition: { duration: 0.6 } }}>
                        <HeartFilled onClick={() => setHearts([...hearts, { id: GetUniqueId() }])} className="heart" />
                    </motion.div>

                    <motion.div
                        className="FromToDate"
                        initial={{ opacity: 0, y: '30px' }}
                        animate={{ opacity: 1, y: '0', transition: { duration: 0.6, delay: 0.42 } }}
                        exit={{ opacity: 0, y: '-30px', transition: { duration: 0.6 } }}>
                        {FirstDate.date() < 10 ? `0${FirstDate.date()}` : FirstDate.date()}/{FirstDate.month() < 10 ? `0${FirstDate.month()}` : FirstDate.month() + 1}/{FirstDate.year()} - {today.date() < 10 ? `0${today.date()}` : today.date()}/{today.month() + 1 < 10 ? `0${today.month() + 1}` : today.month() + 1}/{today.year()}
                    </motion.div>
                </div>

                {/* Second Person */}
                <PersonModel image={secondPersonImage} person={secondPersonInfo} />
            </div>
            {hearts.map((heart) => {
                setTimeout(() => removeHeart(heart.id), 2800)
                return <HeartFilled className={`animatedHeart ${parseFloat(heart.id) % 2 === 0 ? 'left' : 'right'}`} key={heart.id} />
            })}
        </>
    )
}