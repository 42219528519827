import { Table, Input, Popconfirm, Button } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { IPlaces, deleteActivities, addActivities, resetActivities } from './RandomPlacesServices'
import { useState } from 'react'

interface IProps {
    activitiesList: IPlaces[];
    setActivitiesList: any;
    setResult: any;
}
export const RandomPlacesEdit = ({ activitiesList, setActivitiesList, setResult }: IProps) => {
    const [inputName, setInputName] = useState<string>('')
    const columns = [
        // {
        //     title: 'ID',
        //     width: '25%',
        //     dataIndex: 'id',
        //     key: 'id',
        // },
        {
            title: 'Activities',
            dataIndex: 'activity',
            width: '50%',
            key: 'type',
        },
        {
            title: <>
                <Input
                    style={{ width: '80%' }}
                    value={inputName}
                    onChange={(e) => setInputName(e.target.value)}
                    placeholder="Add Activity"
                    maxLength={12}
                />
                <PlusOutlined className="addBtn" onClick={() => addActivities(inputName, activitiesList, setActivitiesList, setInputName)} />
            </>,
            key: 'action',
            width: '50%',
            render: (activity: IPlaces) => {
                return (
                    <Popconfirm title={`Do you want to remove ${activity.activity}?`}
                        onConfirm={() => deleteActivities(activity, activitiesList, setActivitiesList)}
                    >
                        <DeleteOutlined className="deleteBtn" />
                    </Popconfirm>
                )
            }
        }
    ]
    return (
        <>
            <Table
                columns={columns}
                rowKey="id"
                dataSource={activitiesList}
                scroll={{ y: window.innerHeight / 2 }}
                pagination={false}
                summary={() => (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                                <Popconfirm title="Do you want to reset?" description="Activities list will be reset to the default list" onConfirm={() => resetActivities(setActivitiesList, setResult)}>
                                    <Button type="primary">Reset</Button>
                                </Popconfirm>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}><i>Scroll to view more activities</i></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )
                }
            />
        </>
    )
}