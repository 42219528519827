export const places = [
    {
        id: 1,
        activity: 'Trượt băng',
    },
    {
        id: 2,
        activity: 'Tô Tượng',
    },
    {
        id: 3,
        activity: 'Xem phim',
    },
    {
        id: 4,
        activity: 'Gội đầu',
    },
    {
        id: 5,
        activity: 'Cafe',
    },
    {
        id: 6,
        activity: 'Workshop',
    },
]