import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

interface IChildren {
    key: number,
    display: string,
    path: string,
}

interface IProps {
    children: IChildren[]
}


export const Dropdown = ({ children }: IProps) => {
    //animation
    const fistPos = {
        opacity: 0,
        transform: 'translateY(-20px)'
    }
    const animateIn = {
        opacity: 1,
        transform: 'translateY(0)'
    }

    return (
        <ul className="dropdown">
            {children.map((child, i) => {
                return (
                    <li className="navBtn dropdownItem" key={child.key}>
                        <Link to={`${child.path}`}>{child.display}</Link>
                    </li>
                )
            })}
        </ul>
    )
}