import './_App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { DaysCounter } from './Layout/DaysCounter/DaysCounter';
import { ConfigProvider } from "antd";
import { Navbar } from './Layout/Navbar/Navbar';
import { RandomFoods } from './Layout/Random/Foods/RandomFoods'
import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { RandomPlaces } from './Layout/Random/Places/RandomPlaces';
import { Music } from './Layout/Music/Music';

const backgroundLocation = 'DaysCounterBG.jpg'
const RouteDirect = [
  {
    path: '/*',
    element: <DaysCounter />,
  },
  {
    path: '/randomfoods',
    element: <RandomFoods />,
  },
  {
    path: '/randomactivities',
    element: <RandomPlaces />,
  },
  {
    path: '/music',
    element: <Music />,
  },
]

function App() {
  //check if user using ios/safari
  const [isIos, setIsIos] = useState<boolean>(false)
  const [landScapePhone, setLandScapePhone] = useState<boolean>(false)
  const [device, setDevice] = useState<string>('')
  const [phone, setPhone] = useState<boolean>(false)
  useEffect(() => {
    const devicesCheck = navigator.userAgent.split("(")
    const AppleDevices = /iPhone|Mac OS|Macintosh|IPad|IPod/.test(devicesCheck[1])

    AppleDevices && setIsIos(true)
    setDevice(devicesCheck[1].split(")")[0])
    // console.log(navigator.userAgent)
  }, [])

  //Check if it is phone landscape or portrait
  useEffect(() => {
    const isPhone = /iPhone|Android|Windows Phone|IPad|IPod/.test(navigator.userAgent);
    setPhone(isPhone)
    // console.log(navigator.userAgent)
    isPhone && (window.innerHeight < window.innerWidth) ? setLandScapePhone(true) : setLandScapePhone(false)
    window.addEventListener("orientationchange", function () {
      // console.log(window.innerHeight < window.innerWidth);

      isPhone && (window.innerHeight > window.innerWidth) ? setLandScapePhone(true) : setLandScapePhone(false)
    }, false);
  }, [])

  return (
    <div className='App' >
      <AnimatePresence>
        {isIos &&
          <motion.div
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="Cover" onClick={() => setIsIos(false)}>
            <div className="title">Your device is running on IOS/MacOs</div>
            <div className="content">This may cause some animation errors</div>
            <div className="clickSpotAction">CLICK TO CONTINUE</div>

          </motion.div>}
      </AnimatePresence>

      <AnimatePresence>
        {landScapePhone &&
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="Cover">
            <div className="title">You can not use Landscape on mobile</div>
            <div className="content">This will reduce your experience</div>
            <div className="clickSpotAction">Turn to portrait to continue</div>
          </motion.div>}
      </AnimatePresence>

      <img className="DaysCounterBG" style={phone ? {height: '100%'} : {width: '100%'}} src={backgroundLocation} />

      <BrowserRouter>
        <ConfigProvider theme={{ token: { colorPrimary: "#daa29e" } }}>
          <Navbar />
          <Routes>
            {RouteDirect.map((route, i) => {
              return <Route path={route.path} element={route.element} />
            })}
          </Routes>
        </ConfigProvider>
      </BrowserRouter>
      <div className="devices">{device}</div>
    </div>
  );
}

export default App;
