import {Button} from 'antd'
import {motion} from 'framer-motion'

interface IProps {
    isOpen: boolean
    action: () => void
}

const animationOri = {
    opacity: 0
}
const animationIn = {
    opacity: 1
}
const transition = {
    duration: 0.8
}


export const MenuBtn = ({isOpen, action}: IProps) => {
    return (
        <motion.div className="MenuBtn" onClick={action}
            initial={animationOri}
            animate={animationIn}
            transition={transition}
        >
            <span className={isOpen ? `open` : `close`}></span>
            <span className={isOpen ? `open` : `close`}></span>
            <span className={isOpen ? `open` : `close`}></span>
        </motion.div>
    )
}