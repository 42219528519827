import { IFoods, DeleteFood, AddFood, ResetFoodList } from "./RandomFoodsServices"
import { Table, Input, Popconfirm, Button } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useState } from 'react'

interface IProps {
    foodsList: IFoods[];
    setFoodsList: any;
}

export const FoodsRandomEdit = ({ foodsList, setFoodsList }: IProps) => {
    const [inputName, setInputName] = useState<string>('')
    const columns = [
        // {
        //     title: 'ID',
        //     width: '25%',
        //     dataIndex: 'id',
        //     key: 'id',
        // },
        {
            title: 'Food',
            dataIndex: 'name',
            width: '50%',
            key: 'name',
        },
        {
            title: <>
                <Input
                    style={{ width: '80%' }}
                    value={inputName}
                    onChange={(e) => setInputName(e.target.value)}
                    placeholder="Add Food"
                    maxLength={12}
                />
                <PlusOutlined className="addBtn" onClick={() => AddFood(inputName, foodsList, setFoodsList, setInputName)} />
            </>,
            key: 'action',
            width: '50%',
            render: (food: IFoods) => {
                return (
                    <Popconfirm title={`Do you want to remove ${food.name}?`}
                        onConfirm={() => DeleteFood(food, foodsList, setFoodsList)}
                    >
                        <DeleteOutlined className="deleteBtn" />
                    </Popconfirm>
                )
            }
        }
    ]

    return (
        <>
            <Table
                columns={columns}
                rowKey="id"
                dataSource={foodsList}
                scroll={{ y: window.innerHeight / 2 }}
                pagination={false}
                summary={() => (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                                <Popconfirm title="Do you want to reset?" description="Foods list will be reset to the default list" onConfirm={() => ResetFoodList(setFoodsList)}>
                                    <Button type="primary">Reset</Button>
                                </Popconfirm>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}><i>Scroll to view more foods</i></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )
                }
            />
        </>
    )
}