import "./Navbar.scss"
import { Link } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'
import { motion, AnimatePresence } from 'framer-motion'
import { Dropdown } from "./Dropdown"
import { MenuBtn } from "./MenuBtn"
import { useEffect, useState } from "react"

export const Navbar = () => {
    const RoutesContent = [
        {
            key: 1,
            display: 'Home',
            path: '/home',
            children: [],
        },
        {
            key: 2,
            display: 'Random',
            children: [
                {
                    key: 2.1,
                    display: 'Foods',
                    path: '/randomfoods'
                },
                {
                    key: 2.2,
                    display: 'Activities',
                    path: '/randomactivities'
                }
            ]
        },
        {
            key: 3,
            display: 'Music',
            path: '/music',
            children: [],
        },
    ]

    //animation
    const fistPos = {
        opacity: 0,
        transform: 'translateY(-20px)'
    }
    const animateIn = {
        opacity: 1,
        transform: 'translateY(0)'
    }
    const animateOut = {
        opacity: 0,
        transform: 'translateY(-30px)'
    }
    
    const [menuState, setMenuState] = useState<boolean>(false)
    useEffect(() => {
        const innerWidth = window.innerWidth;
        innerWidth > 600 && setMenuState(true)
    }, [])


    return (
        <div className="Navbar">
            {/* Menu */}
            <MenuBtn isOpen={menuState} action={() => setMenuState(!menuState)} />

            {/* Print list > 600px */}
            <AnimatePresence key={`nav-presence`}>
                {menuState && <ul className="NavBtnContainer">
                    {
                        RoutesContent.map((route, i) => {
                            return (
                                route.children.length === 0 ?
                                    <motion.li
                                        className="navBtn"
                                        key={`nav-Btn-${route.key}`}
                                        initial={fistPos}
                                        animate={animateIn}
                                        transition={{ duration: 0.5, delay: i - i * 0.75 }}
                                        exit={animateOut}
                                    >
                                        <Link to={`${route.path}`}>{route.display}</Link>
                                    </motion.li>
                                    :
                                    (
                                        <motion.li
                                            className="navBtn"
                                            key={`nav-Btn-${route.key}`}
                                            initial={fistPos}
                                            animate={animateIn}
                                            transition={{ duration: 0.5, delay: i - i * 0.75 }}
                                            exit={animateOut}
                                        >
                                            {route.display}<DownOutlined className="navIcon" />
                                            <Dropdown children={route.children} />
                                        </motion.li>

                                    )
                            )
                        })
                    }
                </ul>}
            </AnimatePresence>
        </div>
    )
}