import dayjs, { Dayjs } from 'dayjs'
import { motion } from 'framer-motion'

export interface IPersonInfo {
    name: string;
    birth: Dayjs;
}

interface IProps {
    image: string;
    person: IPersonInfo;
}

export const PersonModel = ({ image, person }: IProps) => {
    return (
        <div className="person">
            <motion.div className="ImgContainer"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}>
                <img src={image} className="personImg" alt="Loading..." />
            </motion.div>
            <motion.div className="info"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, x: '0', transition: { duration: 1, delay: 0.2 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}>
                {person.name} {dayjs().year() - person.birth.year()}
            </motion.div>
        </div>
    )
}